import {mapGetters, mapMutations, mapActions} from 'vuex'

export default {
  name: 'unavailableProducts',
  props: {
    products: {
      type: Array,
      default() {
        return []
      }
    }
  },
  //---data
  data () {
    return {
      isShow: null,
    }
  },
  //---hooks
  created() {
    this.isShow = true;
  },
  mounted() {},
  computed: {
    ...mapGetters({
      popupStore: 'system/popupStore'
    }),
  },
  watch: {},
  //---methods
  methods: {
    ...mapMutations({
      closePopup: 'system/SYSTEM_POPUP_CLOSE',
    }),
    ...mapActions({}),
    close() {
      this.isShow = false;
      setTimeout(()=>{
        this.closePopup('unavailableProducts');
      },400);
    }
  },
  destroyed() {
    if (this.popupStore['unavailableProducts']) {
      this.closePopup('unavailableProducts');
    }
  }
}