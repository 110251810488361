import {mapActions, mapGetters, mapMutations} from 'vuex';
import orderProducts from './components/order-products/index.vue';
import orderParcel from './components/order-parcel/index.vue';
import emptyProductVendor from '@/components/popups/empty-product-vendor/index.vue';

export default {
  name: 'orders.show',
  data() {
    return {}
  },
  components: {
    orderProducts,
    orderParcel,
    emptyProductVendor,
  },
  created() {
    this.fetchOrder(parseInt(this.$route.params.orderId));
  },
  mounted() {
  },
  computed: {
    ...mapGetters({
      order: 'orders/order',
      isOrderLoading: 'orders/isOrderLoading',
      isOrderConfirmLoading: 'orders/isOrderConfirmLoading',
      isOrderParcelsCreateLoading: 'orders/isOrderParcelsCreateLoading',
      parcel: 'parcels/parcel',
      popupStore: 'system/popupStore',
    }),
    client() {
      return this.order.address.state ? this.order.address : null
    },
    getClientAddress() {
      let address = '';
      if (this.client) {
        let state = this.client.state ?? '',
            code = this.client.postal_code ? ', ' + this.client.postal_code : '',
            city = this.client.address_3 ? ',<br>' + this.client.address_3 : '',
            street = this.client.address_2 ? ', ' + this.client.address_2 : '';

        address = `${state}${code}${city}${street}`
      }
      return address
    },
    orderStatus() {
      return this.order.order_status.data.name
    },
    isHasAllProductInStore() {
      //#todo check parcel availability propduct for border
      return this.order.products.data.every(item => {
        let count = -1;
        if (this.orderStatus === 'new') {
          count = item.on_store_count - item.reserved_count - item.order_count;
        } else {
          count = item.on_store_count - item.order_count;
        }
        return count >= 0;
      })
    },
    isCanCreateParcel() {
      return (
        this.orderStatus === 'awaiting' ||
        this.orderStatus === 'new' && this.order.payment_status === 'paid'
      ) && this.isHasAllProductInStore
    },
    isCanConfirm() {
      return this.orderStatus === 'new' &&
        this.order.payment_status === 'paid' &&
        !this.isHasAllProductInStore
    },
    emptyProductsVendor() {
      return this.order.products.data.filter(product => {
        return product.vendor_id === null
      });
    },
  },
  methods: {
    ...mapActions({
      fetchOrder: 'orders/ORDERS_REQUEST_DATA_GET',
      confirmOrder: 'orders/ORDERS_REQUEST_DATA_CONFIRM',
      createParcelsOrder: 'orders/ORDERS_REQUEST_PARCELS_CREATE',
    }),
    ...mapMutations({
      openPopup: 'system/SYSTEM_POPUP_OPEN',
      closePopup: 'system/SYSTEM_POPUP_CLOSE',
    }),
    toBack() {
      this.$router.go(-1);
    },
    confirm() {
      if (this.emptyProductsVendor.length) {
        this.openPopup('emptyProductVendor');
      } else {
        this.confirmOrder(this.order.id)
          .then(() => {
            this.$toasted.success(this.$t('orderConfirmSuccessMessage'));
          }).catch(error => {
          if (error.response.status === 422) {
            this.$toasted.error(error.response.data.message);
          }
        });
      }
    },
    createParcels() {
      this.createParcelsOrder(this.order.id)
        .then(() => {
          this.$toasted.success(this.$t('orderCreateParcelsSuccessMessage'));
        }).catch(error => {
        if (error.response.status === 422) {
          this.$toasted.error(error.response.data.message);
        }
      });
    },
  },
  destroyed() {
    if (this.popupStore['emptyProductVendor']) {
      this.closePopup('emptyProductVendor');
    }
  }
}
