import {mapGetters, mapActions, mapMutations} from 'vuex';
import productStatus from './product-status/index.vue';

export default {
  name: 'orderProducts',
  props: {
    orderStatus: {
      type: String,
      default: ''
    },
    products: {
      type: Array,
      default: []
    },
  },
  data() {
    return {
      productPage: []
    }
  },
  components: {
    productStatus
  },
  created() {},
  mounted() {},
  computed: {
    ...mapGetters({}),
  },
  watch: {},
  methods: {
    ...mapActions({}),
    ...mapMutations({
      setProduct: 'product/PRODUCT_DATA',
    }),
    getAvailability(item) {
      return item.on_store_count - item.reserved_count > 0 ? item.on_store_count - item.reserved_count : 0;
    },
    getAmount(item) {
      return item.price * item.order_count;
    },
    getPage(page) {
      this.productPage = page;
    },
    show(product) {
      this.setProduct(product);
      this.$router.push({name: 'products.show', params: {productId: product.id}}).catch(() => {console.log();});
    }
  },
  destroyed() {},
}
