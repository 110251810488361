import {mapGetters, mapMutations, mapActions} from 'vuex'
import {formatBarcode} from "@/utils/barcode";

export default {
  name: 'editDeliveryCode',
  props: {
    parcelId: {
      type: Number,
      default: 0,
    },
    parcelStatus: {
      type: String,
      default: '',
    },
    boxes: {
      type: Array,
      default() {
        return []
      },
    },
  },
  //---components
  components: {},
  //---data
  data () {
    return {
      isShow: null,
      boxData: {
        currentIndex: 0,
        list: [],
        keyCodeArray: [],
      },
    }
  },
  //---hooks
  created() {
    this.isShow = true;

    this.fillData();
  },
  mounted() {
    this.setInputFocus('boxBarcodeInput',this.boxData.currentIndex);
  },
  computed: {
    ...mapGetters({
      popupStore: 'system/popupStore',
    }),
    isAllBoxesSetCode() {
      return this.boxData.currentIndex + 1 > this.boxes.length;
    },
  },
  watch: {},
  //---methods
  methods: {
    ...mapMutations({
      closePopup: 'system/SYSTEM_POPUP_CLOSE',
    }),
    ...mapActions({
      updateBox: 'boxes/BOX_REQUEST_UPDATE',
    }),
    close() {
      this.isShow = false;
      setTimeout(()=>{
        this.closePopup('editDeliveryCode');
      },400);
    },

    fillData(){
      this.boxData.list = this.boxes.map(box => {
        return {
          barcode: null,
          isLoading: false,
          isUpdated: false,
          data: box
        }
      })
    },

    getBoxTitle(data) {
      return data.box_type.data.title;
    },

    getBoxPlace(data) {
      return `${data.storage.rack.title}, ${data.storage.shelf.title}, ${data.storage.place.title}`;
    },

    setInputFocus(name, index = null) {
      setTimeout(() => {
        this.$refs[name][index].focus();
      },100);
    },

    keyEventCodeReading($event, index) {
      if ($event.keyCode === 13){
        this.boxData.list[index].barcode = formatBarcode(this.boxData.keyCodeArray);
        this.boxData.keyCodeArray = [];
        this.setCode(index);
      } else {
        this.boxData.keyCodeArray.push($event);
      }
    },

    setCode(index) {
      this.boxData.list[index].isLoading = true;
      this.updateBox({
        boxId: this.boxData.list[index].data.id,
        payload: {
          delivery_code: this.boxData.list[index].barcode,
        }
      }).then(() => {
        this.$toasted.success(this.$t('parcelCollectBox'));
        this.boxData.list[index].isUpdated = true;
        this.boxData.currentIndex++;
        if (!this.isAllBoxesSetCode) {
          this.setInputFocus('boxBarcodeInput',this.boxData.currentIndex);
        }
      }).catch(error => {
        if (error.response.status === 422) {
          this.$toasted.error(error.response.data.message);
        }
      }).finally(()=>{
        this.boxData.list[index].isLoading = false;
      });
    },
  },
  destroyed() {},
}