import {mapGetters, mapMutations, mapActions} from 'vuex'
import {cloneDeep, find, findIndex, uniqBy} from "lodash";
import {formatBarcode} from "@/utils/barcode";

export default {
  name: 'boxesPlacement',
  props: {
    boxes: {
      type: Array,
      default() {
        return []
      }
    }
  },
  //---data
  data () {
    return {
      isShow: null,

      boxData: {
        currentIndex: 0,
        barcode: null,
        keyArray: [],
        data: null,
        isMatched: false,
        isPlaced: false
      },
      placeData: {
        data: null,
        barcode: null,
        keyArray: [],
        isLoading: false,
        isFetched: false,
        isNewPlace: false,
        isSuccess: false
      },
    }
  },
  //---hooks
  created() {
    this.isShow = true;
  },
  mounted() {
    this.setBoxData();
  },
  computed: {
    ...mapGetters({
      popupStore: 'system/popupStore',
      isBoxUpdating: 'boxes/isBoxUpdating',
      place: 'places/place',
    }),
    getBoxTitle() {
      return this.boxData.data.box_type.data.title
    },
    getPlaces() {
      let stores = [];

      let placedBoxes = this.boxes.filter(box => { return box.storage })

      if (placedBoxes.length) {
        stores = uniqBy(placedBoxes, item => {
          return item.storage && item.storage.store.id
        }).map(item => {
          return cloneDeep(item.storage.store)
        });

        stores.forEach(store => {
          store.fullPlaces = [];
          placedBoxes.forEach(box => {
            if (box.storage.store.id === store.id) {
              store.fullPlaces.push(box.storage)
            }
          });
        });
      }

      return stores
    },
    isAllBoxPlacement() {
      return this.boxData.currentIndex + 1 > this.boxes.length;
    },

  },
  watch: {},
  //---methods
  methods: {
    ...mapMutations({
      closePopup: 'system/SYSTEM_POPUP_CLOSE',
    }),
    ...mapActions({
      fetchPlace: 'places/PLACES_REQUEST_DATA_GET',
      updateBox: 'boxes/BOX_REQUEST_UPDATE',
    }),
    close() {
      this.isShow = false;
      setTimeout(()=>{
        this.closePopup('boxesPlacement');
      },400);
    },
    setBoxData() {
      this.boxData.data = this.boxes[this.boxData.currentIndex];
      this.boxData.barcode = null;
      this.boxData.isMatched = false;
      this.boxData.isPlaced = false;

      this.placeData.data = null;
      this.placeData.barcode = null;
      this.placeData.data = false;
      this.placeData.isNewPlace = false;
      this.placeData.isSuccess = false;

      this.setInputFocus('boxBarcodeInput');
    },

    //---barcode
    parseBarcode(code) {
      let barcodeString = null,
        parts = code.toUpperCase().split('-'),
        type = parts[0],
        id = parseInt(parts[1]);

      if (id && ['PL','PA'].indexOf(type) >= 0 ) {
        barcodeString = code;
      } else {
        this.$toasted.error(this.$t('barcodeNotRecognized'));
      }
      return {id, barcodeString}
    },

    setInputFocus(name) {
      setTimeout(() => {
        this.$refs[name].focus();
      },100);
    },

    keyEventBoxReading($event) {
      if ($event.keyCode === 13){
        let {id: id, barcodeString: barcode } = this.parseBarcode(formatBarcode(this.boxData.keyArray));
        this.boxData.keyArray = [];

        if (barcode) {
          this.boxData.barcode = barcode;
          this.checkBoxBarcode(id);
        }
      } else {
        this.boxData.keyArray.push($event);
      }
    },

    checkBoxBarcode(parcelId) {
      if (this.boxData.data.parcel_id === parcelId) {
        this.boxData.isMatched = true;
        this.$toasted.success(this.$t('barcodeBoxFoundInParcel'));
        this.setInputFocus('placeBarcodeInput');
      } else {
        this.$toasted.error(this.$t('barcodeBoxNotFoundInParcel'));
      }
    },

    keyEventPlaceReading($event) {
      if ($event.keyCode === 13){
        let {id: id, barcodeString: barcode } = this.parseBarcode(formatBarcode(this.placeData.keyArray));
        this.placeData.keyArray = [];

        if (barcode) {
          if (this.placeData.barcode && this.placeData.isSuccess) {
            if (barcode === this.placeData.barcode ) {
              this.placeData.barcode = barcode;
              this.confirmPlace(id);
            } else {
              this.$toasted.error(this.$t('barcodePlaceNotMatched'));
              this.placeData.barcode = barcode;
              this.checkPlaceBarcode(id);
            }
          } else {
            this.placeData.barcode = barcode;
            this.checkPlaceBarcode(id);
          }
        }
      } else {
        this.placeData.keyArray.push($event);
      }
    },

    checkPlaceBarcode(id) {
      this.placeData.isLoading = true;
      this.fetchPlace(id).then(()=>{
        this.placeData.isFetched = true;
        this.placeData.isSuccess = true;
        this.checkProductPlace();
      }).catch(error => {
        if (error.response.status === 404) {
          this.placeData.isFetched = true;
          this.placeData.isSuccess = false;
          this.$toasted.error(this.$t('barcodePlaceNotFound'));
        }
      }).finally(() => {
        this.placeData.isLoading = false;
      });
    },

    //---additional

    checkProductPlace() {
      let index = findIndex(this.boxes, box => {
        return box.storage.place.id === this.place.id;
      });
      this.placeData.isNewPlace = index === -1;
    },

    cancelPlace() {
      this.$toasted.success(this.$t('barcodePlaceCancel'));
      this.setBoxData();
    },

    confirmPlace(placeId) {
      this.placeData.isLoading = true;

      let data = {
        boxId: this.boxData.data.id,
        payload: {
          place_id: parseInt(placeId)
        }
      }
      this.updateBox(data).then(() => {
        this.$toasted.success(this.$t('barcodePlaced'));
        this.boxData.isPlaced = true;
        this.boxData.currentIndex++;
      }).catch(error => {
        if (error.response.status === 422) {
          this.$toasted.error(error.response.data.message);
        }
      }).finally(()=>{
        this.placeData.isLoading = false;
      });

    },
  }
}