import {mapGetters, mapActions, mapMutations} from 'vuex';

import parcelBoxes from './parcel-boxes/index.vue';
import editDeliveryCode from '@/components/popups/edit-delivery-code/index.vue';
import boxesPlacement from '@/components/popups/boxes-placement/index.vue';
import parcelCollect from '@/components/popups/parcel-collect/index.vue';
import unavailableProducts from '@/components/popups/unavailable-products/index.vue';

import {printBarcodes} from "@/utils/barcode";
import {createCSVAddresses, createCSVProducts} from "@/utils/csv";
import {cloneDeep, uniqBy} from "lodash";

export default {
  name: 'orderParcel',
  props: {
    order: {
      type: Object,
      default() {
        return {}
      }
    },
    parcel: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      isParcelStatusUpdating: false
    }
  },
  components: {
    parcelBoxes,
    editDeliveryCode,
    boxesPlacement,
    parcelCollect,
    unavailableProducts
  },
  created() {},
  mounted() {},
  computed: {
    ...mapGetters({
      user: 'profile/user',
      popupStore: 'system/popupStore'
    }),
    getParcelStatus () {
      return this.parcel.status.data.title
    },
    getBoxes () {
      return this.parcel.boxes.data
    },
    getCollectedBoxes () {
      return this.parcel.boxes.data.filter(box => {return box.status === 'ready'})
    },
    getUncollectedBoxes () {
      let uncollectedBoxes = [];
      this.parcel.boxes.data.forEach(box => {
        let isUncollectedBox = box.box_product.data.some(boxProduct => {
          return boxProduct.packing_requests.data.some(packRequest => {
            return packRequest.packed_count < packRequest.count;
          })
        });
        if (isUncollectedBox) {
          uncollectedBoxes.push(cloneDeep(box))
        }
      })
      return uncollectedBoxes
    },
    getBoxesForDeliveryCode () {
      return this.parcel.boxes.data.filter(box => {return box.status === 'ready' || box.status === 'finished'})
    },

    getUnavailableProducts() {
      let boxProducts = [];

      this.parcel.boxes.data.forEach(box => {
        box.box_product.data.forEach(box_product => {
          boxProducts.push({
            product: box_product.product,
            packingRequests: box_product.packing_requests,
            packingRequestsLeft: box_product.packing_requests.data.reduce((acum, current) => {
              return acum + (current.count - current.packed_count)
            }, 0)
          });
        });
      });

      let uniqProducts = cloneDeep(uniqBy(boxProducts, boxProduct => { return boxProduct.product.data.id }))

      uniqProducts.forEach(uniqProduct => {
        uniqProduct.totalLeft = 0;
        boxProducts.forEach(boxProduct => {
          if (uniqProduct.product.data.id === boxProduct.product.data.id) {
            uniqProduct.totalLeft = uniqProduct.totalLeft + boxProduct.packingRequestsLeft
          }
        });
      });

      return uniqProducts.filter(uniqProduct => {
        return uniqProduct.totalLeft > uniqProduct.product.data.on_store_count
      })
    }
  },
  watch: {},
  methods: {
    ...mapActions({
      updateParcelStatus: 'parcels/PARCELS_REQUEST_DATA_UPDATE'
    }),
    ...mapMutations({
      openPopup: 'system/SYSTEM_POPUP_OPEN',
      closePopup: 'system/SYSTEM_POPUP_CLOSE',
    }),
    getBarcode () {
      printBarcodes([ this.parcel.guid ]);
    },
    saveAddresses() {
      createCSVAddresses([this.order.address]);
    },
    saveProducts() {
      createCSVProducts(this.order.products.data);
    },
    editDeliveryCode() {
      this.openPopup('editDeliveryCode');
    },
    placeBoxes() {
      this.openPopup('boxesPlacement');
    },
    collectParcel() {
      if (!this.getUnavailableProducts.length) {
        if (this.user.id !== this.parcel.administrator.data.id) {
          this.isParcelStatusUpdating = true;
          this.updateParcelStatus({
            parcelId: this.parcel.id,
            payload: {
              status: 'collecting'
            }
          }).then(() => {
            this.$toasted.success(this.$t('parcelGetInWork'));
            this.openPopup('parcelCollect');
          }).catch(error => {
            if (error.response.status === 422) {
              this.$toasted.error(error.response.data.message);
            }
          }).finally(()=>{
            this.isParcelStatusUpdating = false
          });
        } else {
          this.openPopup('parcelCollect');
        }
      } else {
        this.openPopup('unavailableProducts');
      }
    },
  },
  destroyed() {},
}
