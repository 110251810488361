//1. set worker for parcel
//##send request for update parcel(status: collecting)
//...check what box is not collected fully, create uncollected boxes list
//* if some product in box are not collected all, but present, then skip 2-4
//2. scan place for template box
//3. scan product of template box
//4. repeat scan product for confirm template box
//5. scan place for product item
//6. scan product item
//7. repeat scan product item for confirm
//##send request for update pack record
//REPEAT 6-7 for all product item
//8. goto next product for box
//REPEAT 5-8 step for all product from box
//##send request for box placement and change box status (only status: ready)
//REPEAT 2-8 step for all box
//9. change status for parcel
//##send request for update parcel(status: ready)

import {mapGetters, mapMutations, mapActions} from 'vuex'

import {formatBarcode} from "@/utils/barcode";
import {cloneDeep, findIndex} from "lodash";

export default {
  name: 'parcelCollect',
  props: {
    parcelId: {
      type: Number,
      required: true,
      default: 0,
    },
    boxes: {
      type: Array,
      required: true,
      default() {
        return []
      },
    }
  },
  //---components
  components: {},
  //---data
  data() {
    return {
      isShow: null,

      boxData: {
        currentIndex: 0,
        data: null,
        isCollecting: false,
        placeBarcode: null,
        placeKeyArray: [],
        isPlaceLoading: false,
        placeData: null,
        productBarcode: null,
        productKeyArray: [],
        isProductMatched: false,
        isProductConfirmed: false
      },

      productData: {
        list: [],
        data: null,
        currentProductIndex: 0,
        currentPackIndex: 0,
        packs: [],
        keyArray: [],
      },

      isParcelFinished: false,
      isUpdatingStatus: false
    }
  },
  //---hooks
  created() {
    this.isShow = true;
  },
  mounted() {
    this.setBoxData(this.boxes[0]);

  },
  computed: {
    ...mapGetters({
      popupStore: 'system/popupStore',
      place: 'places/place',
    }),
    getTemplateBoxPlace() {
      if (this.boxData.data) {
        let place = this.boxData.data.box_type.data.product.data.consignments.data[0].places.data[0]
        return place ? `${place.shelf.data.rack.data.title},${place.shelf.data.title},${place.title}`: '';
      }
      return ''
    },
    getTemplateBoxPlacement() {
      let place = this.boxData.placeData;
      return place ? `${place.shelf.data.rack.data.title},${place.shelf.data.title},${place.title}`: '';
    },
    isAllBoxesCollected() {
      return this.boxData.currentIndex + 1 > this.boxes.length;
    },
    isAllProductsCollected() {
      return this.productData.currentProductIndex + 1 > this.productData.list.length;
    },
    isAllPacksCollected() {
      return this.productData.currentPackIndex + 1 > this.productData.packs.length;
    }
  },
  watch: {},
  //---methods
  methods: {
    ...mapMutations({
      closePopup: 'system/SYSTEM_POPUP_CLOSE',
    }),
    ...mapActions({
      fetchProduct: 'products/PRODUCTS_REQUEST_DATA_GET',
      fetchPlace: 'places/PLACES_REQUEST_DATA_GET',
      updateSupplyOrdersProduct: 'supplyOrders/SUPPLY_ORDERS_REQUEST_PRODUCT_UPDATE',
      updatePack: 'packingRequests/PACKING_REQUEST_UPDATE',
      updateBox: 'boxes/BOX_REQUEST_UPDATE',
      updateParcelStatus: 'parcels/PARCELS_REQUEST_DATA_UPDATE'
    }),

    //---general
    close() {
      this.isShow = false;
      setTimeout(() => {
        this.closePopup('parcelCollect');
      }, 400);
    },

    setBoxData(box) {
      //---clear prev data
      this.boxData.data = box;
      this.boxData.isCollecting = this.isBoxAlreadyCollecting(box);
      this.boxData.placeBarcode = null;
      this.boxData.placeData = null;
      this.boxData.productBarcode = null;
      this.boxData.isProductMatched = false;
      this.boxData.isProductConfirmed = false;

      if (!this.boxData.isCollecting) {
        this.setInputFocus('boxTemplatePlace');
      } else {
        this.setProductsData(this.boxData.data.box_product.data)
      }
    },

    setProductsData(products) {
      this.productData.currentProductIndex = 0;

      this.productData.list = products.map(product => {
        product.isCollected = product.packing_requests.data.some(packRequest => {
            return packRequest.packed_count >= packRequest.count;
        });

        return product
      });

      this.productData.currentProductIndex = findIndex(this.productData.list, product => {return !product.isCollected});

      this.setProductData(this.productData.list[this.productData.currentProductIndex])
    },

    setProductData(product) {
      this.productData.data = product;
      this.productData.currentPackIndex = 0;
      this.productData.packs = product.packing_requests.data.map(pack => {
        return {
          data: pack,
          placeBarcode: null,
          isPlaceLoading: false,
          isPlaceFetched: false,
          isPlaceMatched: false,
          productBarcode: null,
          isProductMatched: false,
          isProductConfirmed: false,
          isProductLoading: false
        }
      });
      this.setInputFocus('packPlaceBarcodeInput', this.productData.currentPackIndex);
    },

    setInputFocus(name, index = null) {
      setTimeout(() => {
        if (index !== null) {
          this.$refs[name][index].focus();
        } else {
          this.$refs[name].focus();
        }
      }, 100);
    },

    parseBarcode(code) {
      let barcodeString = null,
        parts = code.toUpperCase().split('-'),
        type = parts[0],
        id = parseInt(parts[1]);

      if (id && ['PL', 'PR'].indexOf(type) >= 0) {
        barcodeString = code;
      } else {
        this.$toasted.error(this.$t('barcodeNotRecognized'));
      }
      return {id, barcodeString}
    },

    getPlace(place) {
      return `${place.shelf.data.rack.data.store.data.title}, ${place.shelf.data.rack.data.title}, ${place.shelf.data.title}, ${place.title}`;
    },
    //---END general

    //---template box
    isBoxAlreadyCollecting(box) {
      return box.box_product.data.some(boxProduct => {
        return boxProduct.packing_requests.data.some(packRequest => {
          return packRequest.packed_count > 0;
        })
      });
    },

    keyEventTemplatePlaceReading($event) {
      if ($event.keyCode === 13) {
        let {id: id, barcodeString: barcode} = this.parseBarcode(formatBarcode(this.boxData.placeKeyArray));
        this.boxData.placeKeyArray = [];

        if (barcode) {
          this.boxData.placeBarcode = barcode;
          this.checkTemplatePlace(id);
        }
      } else {
        this.boxData.placeKeyArray.push($event);
      }
    },

    checkTemplatePlace(id) {
      this.boxData.isPlaceLoading = true;
      this.fetchPlace(id).then(() => {
        this.boxData.placeData = cloneDeep(this.place);
        this.setInputFocus('boxTemplateProduct');
      }).catch(error => {
        if (error.response.status === 404) {
          this.$toasted.error(this.$t('barcodePlaceNotFound'));
        }
      }).finally(() => {
        this.boxData.isPlaceLoading = false;
      });
    },

    keyEventTemplateProductReading($event) {
      if ($event.keyCode === 13) {
        let {id: id, barcodeString: barcode} = this.parseBarcode(formatBarcode(this.boxData.productKeyArray));
        this.boxData.productKeyArray = [];

        if (barcode) {
          if (this.boxData.productBarcode) {
            if (barcode === this.boxData.productBarcode) {
              this.boxData.productBarcode = barcode;
              this.boxData.isProductMatched = this.boxData.data.box_type.data.product_id === id;
              this.boxData.isProductConfirmed = true;
              if (this.boxData.isProductMatched) {
                this.boxData.isCollecting = true;
                this.setProductsData(this.boxData.data.box_product.data)
              } else {
                this.$toasted.error(this.$t('parcelCollectPackInvalid'));
              }
            } else {
              this.$toasted.error(this.$t('parcelCollectBarcodeNotMatched'));
              this.boxData.productBarcode = barcode;
              this.boxData.isProductMatched = false;
              this.boxData.isProductConfirmed = false;
            }
          } else {
            this.boxData.productBarcode = barcode;
            this.boxData.isProductMatched = false;
            this.boxData.isProductConfirmed = false;
          }
        }
      } else {
        this.boxData.productKeyArray.push($event);
      }
    },
    //---END template box

    //---product
    keyEventPackPlaceReading($event, index) {
      if ($event.keyCode === 13) {
        let {id: id, barcodeString: barcode} = this.parseBarcode(formatBarcode(this.productData.keyArray));
        this.productData.keyArray = [];
        this.productData.currentPackIndex = index;
        if (barcode) {
          this.productData.packs[this.productData.currentPackIndex].placeBarcode = barcode;
          this.checkPackPlace(id);
        } else {
          this.productData.packs[this.productData.currentPackIndex].isPlaceFetched = false;
          this.productData.packs[this.productData.currentPackIndex].isPlaceMatched = false;
        }
      } else {
        this.productData.keyArray.push($event);
      }
    },

    checkPackPlace(id) {
      let index = this.productData.currentPackIndex;

      this.productData.packs[index].isPlaceLoading = true;
      this.fetchPlace(id).then(() => {
        this.productData.packs[index].isPlaceFetched = true;
        let isMatched = id === this.productData.packs[index].data.place.data.id;
        this.productData.packs[index].isPlaceMatched = isMatched;
        if (isMatched) {
          this.setInputFocus('packProductBarcodeInput', index);
        } else {
          this.$toasted.error(this.$t('parcelCollectPlaceInvalid'));
        }
      }).catch(error => {
        if (error.response.status === 404) {
          this.$toasted.error(this.$t('barcodePlaceNotFound'));
        }
      }).finally(() => {
        this.productData.packs[index].isPlaceLoading = false;
      });
    },

    keyEventPackProductReading($event, index) {
      if ($event.keyCode === 13) {
        let {id: id, barcodeString: barcode} = this.parseBarcode(formatBarcode(this.productData.keyArray));
        this.productData.keyArray = [];

        if (barcode) {
          if (this.productData.packs[index].productBarcode) {
            if (barcode === this.productData.packs[index].productBarcode) {
              this.productData.packs[index].productBarcode = barcode;
              this.productData.packs[index].isProductConfirmed = true;

              let isProductMatched = this.productData.data.product_id === id;
              this.productData.packs[index].isProductMatched = isProductMatched;

              if (isProductMatched) {
                this.confirmPackProduct(index);
              } else {
                this.$toasted.error(this.$t('parcelCollectProductInvalid'));
              }
            } else {
              this.$toasted.error(this.$t('parcelCollectBarcodeNotMatched'));
              this.productData.packs[index].productBarcode = barcode;
              this.productData.packs[index].isProductMatched = false;
              this.productData.packs[index].isProductConfirmed = false;
            }
          } else {
            this.productData.packs[index].productBarcode = barcode;
            this.productData.packs[index].isProductMatched = false;
            this.productData.packs[index].isProductConfirmed = false;
          }
        }
      } else {
        this.productData.keyArray.push($event);
      }
    },

    confirmPackProduct(index) {
      this.productData.packs[index].isProductLoading = true;
      this.updatePack( this.productData.packs[index].data.id).then(() => {
        this.productData.packs[index].data.packed_count++;
        this.productData.packs[index].productBarcode = null;
        this.$toasted.success(this.$t('parcelCollectProductAdded'));

        //---check if last count item
        if (this.productData.packs[index].data.packed_count >= this.productData.packs[index].data.count) {
          this.recalculateFlowState();
        }
      }).catch(error => {
        if (error.response.status === 422) {
          this.$toasted.error(this.$t('barcodePlaceNotFound'));
        }
      }).finally(() => {
        this.productData.packs[index].isProductLoading = false;
      });
    },

    recalculateFlowState() {
      this.productData.currentPackIndex++;
      //---check if last pack item
      if (this.isAllPacksCollected) {
        //---check if product
        this.productData.list[this.productData.currentProductIndex].isCollected = true;
        this.productData.currentProductIndex++;
        if (this.isAllProductsCollected) {

          this.isUpdatingStatus = true;
          this.updateBox({
            boxId: this.boxData.data.id,
            payload: {
              status: 'ready',
            }
          }).then(() => {

            this.$toasted.success(this.$t('parcelCollectBox'));
            this.boxData.currentIndex++;
            //---check if box
            if (this.isAllBoxesCollected) {

              this.updateParcelStatus({
                parcelId: this.parcelId,
                payload: {
                  status: 'ready'
                }
              }).then(() => {
                this.$toasted.success(this.$t('parcelCollected'));
                this.isParcelFinished = true;
              }).catch(error => {
                if (error.response.status === 422) {
                  this.$toasted.error(error.response.data.message);
                }
              }).finally(()=>{
                this.isUpdatingStatus = false;
              });
            } else {
              this.setBoxData(this.boxes[this.boxData.currentIndex]);
              this.isUpdatingStatus = false;
            }

          }).catch(error => {
            if (error.response.status === 422) {
              this.$toasted.error(error.response.data.message);
            }
            this.isUpdatingStatus = false;
          });
        } else {
          this.setProductData(this.productData.list[this.productData.currentProductIndex]);
        }
      } else {
        this.setInputFocus('packPlaceBarcodeInput', this.productData.currentPackIndex)
      }
    }
    //---END product
  }
}