import {mapGetters, mapActions, mapMutations} from 'vuex';

// import parcelPackingRequest from '../../parcel-products/index.vue';

export default {
  name: 'parcelProducts',
  props: {
    products: {
      type: Array,
      default() {
        return []
      }
    },
    boxStatus: {
      type: String,
      default: ''
    },
    boxStorage: {
      type: Object,
      default() {
        return {}
      }
    },
  },
  data() {
    return {}
  },
  components: {
    // parcelPackingRequest
  },
  created() {},
  mounted() {},
  computed: {
    ...mapGetters({}),
  },
  watch: {},
  methods: {
    ...mapActions({}),
    ...mapMutations({}),
  },
  destroyed() {},
}
