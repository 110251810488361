import parcelProducts from './parcel-products/index.vue';

export default {
  name: 'parcelBoxes',
  props: {
    boxes: {
      type: Array,
      default() {
        return []
      }
    },
    parcelStatus: {
      type: String,
      default: ''
    }
  },
  data() {
    return {}
  },
  components: {
    parcelProducts
  },
  created() {},
  mounted() {},
  computed: {},
  watch: {},
  methods: {},
  destroyed() {},
}
