export default {
  name: 'productStatus',
  props: {
    orderStatus: {
      type: String,
      default: ''
    },
    product: {
      type: Object,
      default() {
        return {}
      }
    },
  },
  data() {
    return {
      status: {
        color: 'secondary'
      }
    }
  },
  created() {},
  watch: {
    product: {
      immediate: true,
      handler: function () {
        this.calcStatus();
      }
    },
  },
  methods: {
    calcStatus(){
      if (this.orderStatus === 'new') {
        let count = this.product.on_store_count - this.product.reserved_count - this.product.order_count;
        if (count >= 0) {
          this.status.color = 'success';
          this.status.title = this.$t('orderProductReserved');
        } else {
          this.status.color = 'error';
          this.status.title = this.$t('orderProductEmpty');
        }
      } else {
        let count = this.product.on_store_count - this.product.order_count;
        if (count >= 0) {
          this.status.color = 'success';
          this.status.title = this.$t('orderProductReady');
        } else {
          this.status.color = 'info';
          this.status.title = this.$t('orderProductReserved');
        }
      }
    }
  }
}
